"use client";
import {
  NotificationData,
  NotificationsProps,
  notifications,
} from "@mantine/notifications";

type NotificationType = "info" | "caution" | "error";

type Props = {
  type: NotificationType;
} & NotificationsProps &
  NotificationData;

export function showNotification(props: Props) {
  const { type, ...option } = props;
  const color =
    type === "error" ? "red" : type === "caution" ? "orange" : "blue";
  const id =
    type === "error"
      ? "error-notification"
      : type === "caution"
        ? "caution-notification"
        : "info-notification";
  notifications.show({ ...option, color: color, id: id });
}
